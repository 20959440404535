.item-big-thumb {
    img {
        border-radius: .75rem;
    }
}

.wishlist-btn {
    line-height: 1;
    border: 0;
    color: $white;
    top: 2rem;
    right: 2rem;
    z-index: 10;

    span {
        font-weight: 700;
    }

    >i {
        &::before {
            content: "\f417";
        }
    }

    &.active {
        color: $danger;

        >i {
            &::before {
                content: "\f415";
            }
        }
    }
}

.item-details-content {
    position: relative;
    z-index: 1;

    .item-details-dd {
        position: absolute;
        z-index: 10;
        top: 0;
        right: 0;

        .dropdown-toggle {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            border: 0;
            background-color: $text-gray;

            &::after {
                display: none;
            }
        }

        .dropdown-menu {
            padding: 1rem 0;
            transition: all 500ms;
            width: 10rem;
            background-color: $white;
            border: 2px solid $text-gray;
            box-shadow: 0 1rem 3rem rgba(15, 30, 150, 0.175);
            border-radius: .5rem;

            .dropdown-item {
                font-size: 1rem;
                font-weight: 500;

                &:hover,
                &:focus {
                    background-color: $gray;
                }
            }
        }
    }

    .author-img {
        img {
            max-height: 3rem;
            border-radius: 50%;
        }

        i {
            color: $white;
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            line-height: 1rem;
            z-index: 5;
            top: 0;
            right: 0;
            visibility: hidden;
            opacity: 0;

            &.true {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .bid-ends {
        display: flex;
        align-items: center;

        >div {
            display: block;
            border: 2px solid $border;
            color: $heading;
            padding: .375rem .625rem;
            border-radius: .75rem;
            margin-right: 1rem;
            min-width: 55px;
            text-align: center;

            @media #{$breakpoint-sm} {
                min-width: 80px;
                padding: .375rem 1rem;
            }

            &:last-child {
                margin-right: 0;
            }

            span {
                display: block;
                font-weight: 700;
                font-size: 16px;

                @media #{$breakpoint-sm} {
                    font-size: 20px;
                }

                &:last-child {
                    font-size: 16px;
                }
            }
        }
    }
}

.related-project-area {
    .tns-outer {
        position: relative;
        z-index: 1;
    }

    .tns-controls {
        position: absolute;
        line-height: 1;
        top: -84px;
        right: 0;
        z-index: 10;

        @media #{$breakpoint-md} {
            top: -87px;
        }

        @media #{$breakpoint-lg} {
            top: -92px;
        }


        button {
            transition-duration: 400ms;
            width: 2.5rem;
            height: 2.5rem;
            border: 0;
            border-radius: 50%;
            color: $text;
            font-size: 1.5rem;
            background-color: transparent;

            &:hover {
                background-color: $gray;
                color: $primary;
            }
        }
    }
}

.author-top-content {
    margin-top: 60px;

    @media #{$breakpoint-lg} {
        margin-top: 80px;
    }

    .author-thumbnail {
        position: relative;
        z-index: 1;

        i {
            position: absolute;
            bottom: 1rem;
            left: 1rem;
            line-height: 1;
            z-index: 10;
            font-size: 2rem;
            color: $warning;
        }
    }

    .author-dd {
        position: absolute;
        z-index: 10;
        top: 3rem;
        right: 1rem;

        .dropdown-toggle {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            border: 0;
            background-color: $text-gray;

            &::after {
                display: none;
            }
        }

        .dropdown-menu {
            padding: 1rem 0;
            transition: all 500ms;
            width: 10rem;
            background-color: $white;
            border: 2px solid $text-gray;
            box-shadow: 0 1rem 3rem rgba(15, 30, 150, 0.175);
            border-radius: .5rem;

            .dropdown-item {
                font-size: 1rem;
                font-weight: 500;

                &:hover,
                &:focus {
                    background-color: $gray;
                }
            }
        }
    }
}