.about-card {
    text-align: center;

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4rem;
        height: 4rem;
        background-color: $heading;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1.5rem;
        text-align: center;
        color: $white;
        border-radius: .75rem;

        i {
            font-size: 2rem;
        }

        img {
            max-width: 2.5rem;
        }
    }
}

.counter-wrap {
    border-radius: .75rem;
}