.funto-tab-area {
    &.hidden {
        display: none;
    }
}

.tab--area {
    border-radius: .75rem;
    
    .nav-tabs {
        width: 100%;
        margin-bottom: 2rem;
        border: 0;

        .nav-item {
            margin-bottom: 0;

            .nav-link {
                border: none;
                text-align: center;
                font-size: 1rem;
                border-radius: 6px;
                padding: .5rem 1.5rem;
                font-weight: 700;
                color: $heading;
                background-color: $white;
                margin-right: 1rem;
                border-radius: 15rem !important;

                &.active {
                    background-color: $primary;
                    color: $white;
                }
            }
        }
    }
}