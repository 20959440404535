.dashboard-header,
.dashboard-footer,
.admin-wrapper {
    transition-duration: 400ms;
    width: calc(100% - 0px);
    margin-left: 0;

    @media #{$breakpoint-lg} {
        width: calc(100% - 252px);
        margin-left: 252px;
    }
}

.admin-sidebar-wrap {
    transition-duration: 400ms;
    background-color: $white;
    position: fixed;
    top: 0;
    bottom: 0;
    left: -300px;
    width: 252px;
    height: 100%;
    z-index: 1200;
    border-right: 1px solid $border;

    .overflowY-scroll {
        display: flex;
        flex-direction: column;
        padding: 2rem 1.5rem;
        overflow-y: auto;
        scroll-behavior: smooth;
        scrollbar-width: thin;
        height: 100vh;
    }

    &.sidebar-active {
        left: 0;
    }

    @media #{$breakpoint-lg} {
        left: 0;
    }
}

.user-profile {
    .user-name {
        img {
            max-width: 4rem;
            border-radius: .75rem;
        }
    }
}

.sidenav ul {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-left: 0;

    li {
        text-decoration: none;
        list-style: none;
        display: block;
        margin-bottom: 1.5rem;
        line-height: 1;
        font-size: 14px;
        color: $text;

        a {
            position: relative;
            z-index: 1;
            display: flex;
            align-items: center;
            line-height: 1;
            color: $text;
            font-size: 14px;

            i {
                transition-duration: 350ms;
                font-size: 18px;
                margin-right: .75rem;
                color: $heading;
            }

            &::after {
                transition-duration: 500ms;
                position: absolute;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: $primary;
                top: 50%;
                left: -1rem;
                content: "";
                transform: translateY(-50%);
                opacity: 0;
            }

            &.active,
            &:hover,
            &:focus {
                color: $primary;

                i {
                    color: $primary;
                }

                &::after {
                    opacity: 1;
                }
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.admin-wrapper {
    margin-top: 63px;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: $gray;
    min-height: calc(100vh - 81px);

    @media #{$breakpoint-lg} {
        margin-top: 81px;
    }
}

.create-new-button a {
    position: fixed;
    z-index: 100;
    right: 2rem;
    bottom: 2rem;
    width: 3rem;
    height: 3rem;
    padding: 0;
    border-radius: 50%;
    line-height: 3rem;
    text-align: center;
}

.dashboard-hero-card {
    z-index: 5;

    &::after {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -5;
        background-color: $primary;
        content: "";
        border-radius: 1rem;
        opacity: .8;
    }
}

.dashboard-table {

    th,
    td {
        font-size: 14px;

        a {
            font-size: 14px;
        }
    }
}

.dashboard-activity-tab {
    .tab-content {
        flex: 0 0 100% !important;
        width: 100% !important;
        max-width: 100% !important;
    }

    .nav-link {
        border-radius: 10rem;
        margin-bottom: 0;
        border: 0 !important;
        background-color: $primary;
        padding: 0.375rem 1rem;
        color: $white;
        margin-right: .5rem;
        font-size: 14px;
        font-weight: 500;

        &.active {
            background-color: $info;
            color: $white;
        }
    }
}

.dashboard-tabs,
.settings-tabs {
    .nav-link {
        border-radius: 10rem;
        margin-bottom: 0;
        border: 0 !important;
        background-color: $primary;
        padding: 0.375rem 1rem;
        color: $white;
        margin-right: .5rem;
        font-size: 14px;
        font-weight: 500;

        &.active {
            background-color: $info;
            color: $white;
        }
    }
}

.settings-tabs {
    .nav-link {
        padding: .5rem 1rem;
    }
}

.top-authors-slide,
.top-buyer-slide {

    a {
        border-radius: 50%;
        display: block;
        border: 4px solid $text-gray;

        img {
            border-radius: 50%;
        }
    }
}

.top-author-slide-wrap,
.top-buyer-slide-wrap {
    position: relative;
    z-index: 1;

    .tns-controls {
        position: absolute;
        top: -2.5rem;
        right: 0;
        z-index: 100;

        button {
            transition-duration: 400ms;
            border: 0;
            width: 1.75rem;
            height: 1.75rem;
            border-radius: 50%;
            background-color: $text-gray;

            i {
                line-height: 30px;
            }

            &:nth-child(2) {
                margin-left: .5rem;
            }

            &:hover {
                background-color: $primary;
                color: $white;
            }
        }
    }
}

.dashboard-header {
    .menu-toggler {
        border: 2px solid $border;
        padding: 0;
        border-radius: 0.25rem;
        cursor: pointer;
        font-size: 1.75rem;
        line-height: 1;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media #{$breakpoint-lg} {
            display: none;
        }

        i {
            line-height: 1;
        }
    }

    .search-form {
        width: 7rem;

        @media (min-width:400px) {
            width: 10rem;
        }

        @media (min-width:768px) {
            width: 13rem;
        }

        @media (min-width:1200px) {
            width: 15rem;
        }

        .form-control {
            height: 46px;
        }

        button {
            border: 0;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            background-color: transparent;
            width: 3rem;
            height: 2rem;
            z-index: 100;
            font-size: 18px;
            color: $text;
        }
    }

    &.sticky-on {
        box-shadow: none !important;
    }
}