.header-area {
    position: fixed;
    width: 100%;
    z-index: 1020;
    transition: all 500ms;
    border-bottom: 1px solid $border;
    top: 0;
    left: 0;
    background-color: $white;

    @media #{$breakpoint-lg} {
        background-color: transparent;
    }

    &.sticky-on {
        box-shadow: 0 1rem 3rem rgba(15, 30, 150, 0.175);
        background-color: $white;
    }

    .navbar-toggler {
        color: $heading;
        padding: .25rem .5rem;

        &:focus {
            box-shadow: none;
        }
    }

    .navbar-nav-scroll {
        max-height: 50vh !important;
    }

    .navbar {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;

        @media #{$breakpoint-lg} {
            height: 80px;
            padding-top: 0;
            padding-bottom: 0;
        }

        .navbar-brand {
            padding-top: 0;
            padding-bottom: 0;
            margin-right: 1rem;
            line-height: 1 !important;

            >img {
                max-height: 40px;

                @media #{$breakpoint-lg} {
                    max-height: 60px;
                }
            }

            @media #{$breakpoint-lg} {
                margin-right: 1rem;
            }

            @media #{$breakpoint-xl} {
                margin-right: 2.75rem;
            }

            .dark-logo {
                display: none;
            }
        }
    }

    .navbar-nav {

        >a,
        .nav-link {
            color: $heading;
            font-weight: 500;
            font-size: 16px;
            padding: .375rem .75rem;

            @media #{$breakpoint-lg} {
                font-size: 17px;
                padding: .375rem .5rem;
            }

            @media #{$breakpoint-xl} {
                padding: 1rem .875rem;
            }

            &.active,
            &:hover,
            &:focus {
                color: $primary;
            }
        }

        .dropdown-menu {
            background-color: rgba(194, 212, 248, 0.5);
            width: 100%;
            border: 0;
            border-radius: .5rem;
            margin-left: 0 !important;

            @media #{$breakpoint-lg} {
                animation-name: menuAnimation;
                animation-duration: 300ms;
                animation-fill-mode: both;
                padding: 1rem 0;
                width: 13rem;
                background-color: $white;
                border: 2px solid $text-gray;
                box-shadow: 0 1rem 3rem rgba(52, 54, 75, 0.175);
            }

            >a {
                transition-duration: 400ms;
                display: block;
                font-size: 16px;
                padding: .375rem 1rem;
                color: $heading;

                @media #{$breakpoint-lg} {
                    padding: .375rem 1.5rem;
                }

                &.active,
                &:hover,
                &:focus {
                    background-color: transparent;
                    color: $primary;

                    @media #{$breakpoint-lg} {
                        color: $primary;
                    }
                }
            }
        }

        .ft-dd {
            a {
                display: flex;
                align-items: center;

                &.dropdown-toggle::after {
                    border: 0;
                    content: "\f282";
                    font-family: bootstrap-icons !important;
                    font-size: 12px;
                    transition: all 400ms;
                    line-height: 1 !important;
                    margin-left: auto;

                    @media #{$breakpoint-lg} {
                        margin-left: .3rem;
                    }
                }
            }

            &.show {
                a {
                    &.dropdown-toggle::after {
                        transform: rotate(-180deg);
                    }
                }
            }

            .ft-dd {
                a {
                    transition-duration: 400ms;
                    display: flex;
                    font-size: 16px;
                    padding: .375rem 1rem;
                    align-items: center;

                    @media #{$breakpoint-lg} {
                        padding: .375rem 1.5rem;
                    }

                    &.dropdown-toggle::after {
                        margin-left: auto !important;
                        content: "\f284";
                        font-family: bootstrap-icons !important;
                        font-size: 12px;
                    }
                }

                &.show {
                    a {
                        &.dropdown-toggle::after {
                            transform: rotate(0deg);
                        }
                    }
                }

                >.dropdown-menu {
                    @media #{$breakpoint-lg} {
                        margin-left: .325rem !important;
                    }
                }
            }
        }
    }
}

@keyframes menuAnimation {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 40px, 0);
        -ms-transform: translate3d(0, 40px, 0);
        transform: translate3d(0, 40px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 4px, 0);
        -ms-transform: translate3d(0, 4px, 0);
        transform: translate3d(0, 4px, 0);
    }
}

.header-meta {
    .search-form {
        z-index: 100;

        .form-control {
            width: 120px;
            height: 40px;
            padding: .2rem .875rem;
            border-radius: .5rem;

            @media #{$breakpoint-xl} {
                width: 180px;
            }
        }

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border: 0 !important;
            background-color: $white;
            border-radius: 50%;
            font-size: 14px;
            color: $text;
            right: .5rem;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10;
        }
    }

    .user-dropdown {
        .user-btn {
            border: 0 !important;
            padding: 0;
            font-size: 1.25rem;
            color: $heading;
            width: 40px;
            height: 40px;
            display: inline-block;
            border-radius: .5rem;
            background-color: $gray;
            display: flex;
            align-items: center;
            justify-content: center;

            &::after {
                display: none;
            }
        }

        .dropdown-menu {
            padding: 1rem 0;
            transition: all 350ms ease-in;
            width: 11rem;
            background-color: $white;
            border: 2px solid $text-gray;
            box-shadow: 0 1rem 3rem rgba(15, 30, 150, 0.175);
            border-radius: .5rem;

            .dropdown-item {
                white-space: normal;
                font-size: 1rem;
                font-weight: 500;
                display: flex;
                align-items: center;
                padding: .375rem 1rem;

                i {
                    display: inline-block;
                    flex: 0 0 1.5rem;
                    max-width: 1.5rem;
                    width: 1.5rem;
                    height: 1.5rem;
                    background-color: $text-gray;
                    border-radius: .375rem;
                    color: $heading;
                    line-height: 1.5rem;
                    text-align: center;
                }

                &:hover,
                &:focus {
                    background-color: $gray;
                }
            }

            &.noti-dd-menu {
                width: 14rem;
            }
        }
    }
}